
import '../App.css';
import UtilitiesDesign from './UtilitiesDesign';
import Footer from './Footer';
import HeroSlider from './HeroSlider';
import Header from './Header';
import Slide1 from '../assets/Slide1.jpg'
import Slide3 from '../assets/Slide3.jpg'
import AC from '../assets/AC1.png'
import Coffee from '../assets/Coffee1.png'
import Room from '../assets/Room1.png'
import Jacuzzi from '../assets/Jacuzzi1.png'
import Fridge  from '../assets/Fridge1.png'
import Patio  from '../assets/icons8-sun-lounger-100.png'
import Barbeque  from '../assets/Barbeque1.png'
import LiveMusic  from '../assets/LiveMusic1.png'
import upper1 from '../assets/upper1.png'
import below1 from '../assets/below1.png'
import straight from '../assets/straight.png'


function Home() {
  return (
    <>
   
    <UtilitiesDesign/>
   
    <section className="bg-white dark:bg-gray-900">
    <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-xl text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-Josefin leading-tight text-gray-900 dark:text-white">"Discover the beauty of Nashik from the comfort of Otium Villa, your haven in the heart of wine city."
            </h2>
            
            <a href="#" className="text-white bg-yellow-700 hover:bg-teal-800 focus:ring-4 focus:ring-primary-300 font-large rounded-lg text-lg px-5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Reserve Your Stay</a>
        </div>
    </div>
</section>

{/* <div className="flex flex-col items-center justify-center bg-gray-200 p-1">

      
    </div> */}

{/* <div className="flex justify-center">
      <div className="w-1/2 p-4">
        <div className="bg-gray-500 p-2">
          
          <h2 className="text-xl font-bold mb-2">Content Container 1</h2>
          <p>Some content goes here...</p>
        </div>
      </div>
      <div className="w-1/2 p-4">
        <div className="bg-teal-500 p-2">
          
          <h2 className="text-xl font-bold mb-2">Content Container 2</h2>
          <p>Some content goes here...</p>
        </div>
      </div>
    </div> */}
    
      {/* <div className="container mx-auto h-full relative">
        <div className="flex flex-col lg:flex-row h-full items-center justify-center gap-x-12 text-center lg:text-center lg:pt-12 ">
          
        <div className="flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col text-center ">
            <h1 className="text-6xl font-Playfair text-center">About Us</h1>
            <br/>
            <p className="text-2xl">
            Welcome to Otium Holiday Villa, your home away from home, where Natural Bliss meets Silent Retreat. 
            This is a place to Relax and Rejuvenate, where the world stands still, and tranquility reigns.
            Tucked away from the hustle and bustle of city life, Otium is a symbol of serenity and peace. 
            <br/>
            <br/>
            Our name, 'Otium', is derived from Latin, meaning 'leisure',
            and that's exactly what we promise - a holiday experience drenched in absolute leisure, 
            allowing you to escape from the humdrum of everyday routines and sink into the rejuvenating embrace of relaxation.

            </p>
          </div>


          
        </div>
      </div> */}

<div className="container mx-auto h-full relative">
  <div className="flex flex-col lg:flex-row h-full items-center justify-center gap-x-12 text-center lg:text-center lg:pt-12">
    <div className="flex-1 pt-36 pb-14 lg:pt-0 lg:w-auto z-10 flex flex-col text-center">
    <img src={upper1} alt='' className="w-64 h-25 mx-auto pb-3 " />
      <h1 className="text-6xl font-Staat text-center text-about">About Us</h1>
      <br/>
      <p className="text-3xl font-Cedar  text-gray-700">
        Welcome to <span className="font-bold text-about">Otium Holiday Villa</span>, your home away from home, where Natural Bliss meets Silent Retreat.
        This is a place to <span className="italic">Relax and Rejuvenate</span>, where the world stands still, and tranquility reigns.
        Tucked away from the hustle and bustle of city life, Otium is a symbol of serenity and peace.
        <br/>
        <br/>
        Our name, '<span className="font-bold">Otium</span>', is derived from Latin, meaning 'leisure',
        and that's exactly what we promise - a holiday experience drenched in absolute leisure,
        allowing you to escape from the humdrum of everyday routines and sink into the rejuvenating embrace of relaxation.
      </p>
      <img src={below1} alt='' className="w-64 h-25 mx-auto pt-6 " />
    </div>
  </div>
</div>

{/* <div className="flex flex-col items-center justify-center bg-gray-200 p-1"></div> */}
      <div className="flex-1 pt-36 pb-14 lg:pt-6 lg:w-auto z-10 flex flex-col justify-center items-center">
      {/* <img src={straight} alt='' className="w-64 h-25 mx-auto pb-3 " /> */}
  <h1 className=" text-3xl lg:text-5xl font-Staat text-about pb-4 underline">Amenities and Facilities</h1>
  <br/>
  <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 lg:gap-16">
    <div className="flex items-center ">
      <img src={AC} alt='' className="w-32 h-32" />
      <span className="pl-2  font-Josefin font-bold text-xl">Air Conditioner</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Room} alt='' className="w-32 h-32" />
      <span className="pl-4 font-Josefin font-bold text-xl">Spacious Rooms</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Fridge} alt='' className="w-32 h-32" />
      <span className="pl-6 font-Josefin font-bold text-xl">Refrigerator</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Barbeque} alt='' className="w-32 h-32" />
      <span className="font-Josefin font-bold text-xl">Barbeque - On Request</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Patio} alt='' className="w-32 h-32" />
      <span className="pl-6 font-Josefin font-bold text-xl">Large Patio/Deck</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Coffee} alt='' className="w-32 h-32" />
      <span className="pl-4 font-Josefin font-bold text-xl">Tea & Coffee</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={Jacuzzi} alt='' className="w-32 h-32" />
      <span className="pl-10 font-Josefin font-bold text-xl">Jacuzzi</span>
    </div>
    <div className="flex items-center mt-8 lg:mt-0">
      <img src={LiveMusic} alt='' className="w-32 h-32" />
      <span className="font-Josefin font-bold text-xl">Live Music - On Request</span>
      <br/>
      <span></span>
    </div>
  </div>
  <img src={straight} alt='' className="w-64 h-25 mx-auto pt-8 " />
</div>
    
    </>
    
  );
}
export default Home;