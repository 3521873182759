import React from 'react';

import Footer from './Footer';
import HeroSlider from './HeroSlider';

const UtilitiesDesign = () => {
return(
    <>
    
    <HeroSlider/>
    
    </>
);
};
export default UtilitiesDesign;