import React from 'react'
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from './toast';

async function book(dataToSend){
  
    console.log(dataToSend)
    fetch('http://3.7.234.108:5000/book', {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSend),
    })
      .then(response => {
        if (!response.ok) { throw new Error('Network response was not ok'); }
        return response.json();
      })
      .then(data => { 
        console.log(data);
      toast.success('Thanks for Requesting! We will get back to you shortly!');
      })  
      .catch(error => console.error('There has been a problem with your fetch operation:', error));
}

const Book = () => {
    const [firstName,setFirstName] = useState("");
    const [lastName,setlastName] = useState("");
    const [clientEmail,setClientEmail] = useState("");
    const [fromDate,setFromDate] = useState("");
    const [toDate,setToDate] = useState("");
    const [phoneNumber,setPhonenumber] = useState("");
    const [familySize,setFamilysize] = useState("");
    const [city,setCity] = useState("");
    const [country,setCountry] = useState("");
    const [state,setState] = useState("");
    const [showToast, setShowToast] = useState(false);

    const handleSubmit = (event) => {
      event.preventDefault();
      setShowToast(true);
      book({
        firstName,
        lastName,
        fromDate,
        toDate,
        clientEmail,
        city,
        phoneNumber,
        familySize,
      });

      setFirstName("");
      setlastName("");
      setClientEmail("");
      setFromDate("");
      setToDate("");
      setPhonenumber("");
      setFamilysize("");
      setCity("");
      setCountry("");
      setState("");
    };

    const handleClose = () => {
      setShowToast(false);
    };

    return (
        <>
        
        {showToast && (
        <Toast
          message='Thanks for Requesting! We will get back to you shortly!'
          duration={3000}
          onClose={handleClose}
        />
      )}

        <div className="min-h-screen py-40 md:py-20 lg:py-40">
            <div className="container mx-auto px-2">
                <div className="flex flex-col md:flex-row w-full md:w-11/12 lg:w-10/12 bg-slate-100 rounded-xl mx-auto shadow-xl overflow-hidden">
                 <div className="p-3 flex flex-col gap-4">
              
                <div className="flex flex-grow">
                <iframe className="justify-center items-center flex-grow" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28365825.72979767!2d20.245040497294777!3d29.788476972561323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7611401c36cbb%3A0xd05c9240c5ed9c65!2sOtium%20Villa!5e0!3m2!1sen!2sin!4v1687168261815!5m2!1sen!2sin" width="100%" height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                
                
                </div>   

                <p className="text-sm md:text-lg lg:text-xl">
                <span className="font-Ubuntu">ADDRESS :</span> 
                <br/>
                <span className="font-Josefin">VFQ4+8CF, Take Harsha, Maharashtra 422212</span>
                <br/>
                <span className="font-Ubuntu">CONTACT US: </span>
                <br/>
                <span className="font-Josefin">+91 9890941264, +91 9730154984 | otiumvillanashik@gmail.com</span>
                </p> 
                </div>

                <div className=" py-12 px-4 md:px-12 bg-white">
                 <h2 className="text-lg md:text-xl lg:text-3xl mb-4">Send Request for Availability!</h2>
                 <p className="mb-4">
                    Book your stay with Otium Villa today! 
                 </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                <label>First Name:<br/>
                <input value={firstName} onChange={(event)=>{setFirstName(event.target.value)}} type="text" placeholder="First Name" className="border border-gray-400 py-1 px-2"  />
                </label>
                <label>Last Name:<br/>
                <input value={lastName} onChange={(event)=>{setlastName(event.target.value)}} type="text" placeholder="Last Name Name" className="border border-gray-400 py-1 px-2"  />
                </label>
                </div>
                <div className="mt-10 flex lg:flex-row md:flex flex-col gap-6">
                <label>Size of Family:<br/> 
                <input value={familySize} onChange={(event)=>{setFamilysize(event.target.value)}} type="integer" placeholder="Size of Family" className="border border-gray-400 py-1 px-2"  />
                </label>
                <label>Email:<br/> 
                <input value={clientEmail} onChange={(event)=>{setClientEmail(event.target.value)}} type="text" placeholder="Email" className="border border-gray-400 py-1 px-2"  />
                </label>
                <label>WA No.<br/>
                <input value={phoneNumber} onChange={(event)=>{setPhonenumber(event.target.value)}} type="text" placeholder="WhatsApp No." className="border border-gray-400 py-1 px-2"  />
                </label>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-3 mt-10 gap-5">
                <label> Country : <br/>
                <input  value={country} onChange={(event)=>{setCountry(event.target.value)}} type="text" placeholder="Country" className="border border-gray-400 py-1 px-2"  />
                </label>
                <label> State : <br/>
                <input value={state} onChange={(event)=>{setState(event.target.value)}} type="text" placeholder="State" className="border border-gray-400 py-1 px-2"  />
                </label>
                <label> City : <br/>
                <input value={city}  onChange={(event)=>{setCity(event.target.value)}} type="text" placeholder="City" className="border border-gray-400 py-1 px-2"  />
                </label>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-2 mt-10 gap-5">
                
                              
                <label > From: <br/>
                <input value={fromDate} onChange={(event)=>{setFromDate(event.target.value)}} type="date" className="border border-gray-400 py-1 px-2"/>
                </label>

                <label > To: <br/>
                <input value={toDate} onChange={(event)=>{setToDate(event.target.value)}} type="date" className="border border-gray-400 py-1 px-2"/>
                </label>
                </div> 
                <div className="grid grid-cols-2 mt-20 gap-5">
                
                <button onClick={handleSubmit}  className="text-white bg-yellow-700 hover:bg-teal-800 focus:ring-4 focus:ring-primary-300 font-large rounded-lg text-lg px-2.5 py-2.5 mr-2 mb-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Send Request for Availability</button>
                </div>        
                
              

                </div>

                </div>
            </div>
        </div>
        
        </>
    );
};

export default Book;