import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import otiumLogo from '../assets/otiumLogo.png';

const Header = () => {
  const [header, setHeader] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 50 ? setHeader(true) : setHeader(false);
    });
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header
      className={`${
        header ? 'bg-white py-2 shadow-lg' : 'bg-transparent py-4'
      } fixed z-50 w-full transition-all duration-300`}
    >
      <div className="container mx-auto flex items-center justify-between">
        <Link to="/">
          <img className="w-[130px] lg:w-[200px]" src={otiumLogo} alt="Otium Logo" />
        </Link>
        <div className="relative lg:hidden">
          <button
            className="text-2xl text-black focus:outline-none"
            onClick={toggleMobileMenu}
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M20 12H0v-2h20v2zm0-7H0V3h20v2zm0 12H0v-2h20v2z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {mobileMenuOpen && (
            <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-300 rounded shadow">
              <div className="py-2">
                <Link
                  to="/"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </Link>
                <Link
                  to="/Gallery"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Gallery
                </Link>
                <Link
                  to="/Book"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Book Now
                </Link>
              </div>
            </div>
          )}
        </div>
        <nav className="hidden lg:flex gap-x-4 tracking-[3px] text-[15px] items-center uppercase">
          <Link
            to="/"
            className="text-yellow-600 hover:text-black transition"
            onClick={() => setMobileMenuOpen(false)}
          >
            Home
          </Link>
          <Link
            to="/Gallery"
            className="text-yellow-600 hover:text-black transition"
            onClick={() => setMobileMenuOpen(false)}
          >
            Gallery
          </Link>
          <Link
            to="/Book"
            className="text-yellow-600 hover:text-black transition"
            onClick={() => setMobileMenuOpen(false)}
          >
            Book Now
          </Link>
        </nav>
      </div>
    </header>
  );
};

export default Header;
