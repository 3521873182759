import React, { useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RiRadioButtonLine } from 'react-icons/ri';
import Slide1 from '../assets/Slide1.jpg';
import Slide2 from '../assets/Slide2.jpg';
import Slide3 from '../assets/Slide3.jpg';
import Slide4 from '../assets/Slide4.jpg';
import Slide5 from '../assets/Slide5.jpg';
import Slide6 from '../assets/Slide6.jpg';
import Slide7 from '../assets/Slide7.jpg';
import Slide8 from '../assets/Slide8.jpg';
import Slide9 from '../assets/Slide21.jpg';
import Slide10 from '../assets/Slide10.jpg';
import Slide11 from '../assets/Slide11.jpg';
import Slide12 from '../assets/Slide12.jpg';
import Slide13 from '../assets/Slide13.jpg';
import Slide14 from '../assets/Slide14.jpg';
import Slide15 from '../assets/Slide15.jpg';
import Slide16 from '../assets/Slide16.jpg';
import Slide17 from '../assets/Slide17.jpg';
import Slide18 from '../assets/Slide25.jpg';
import Slide19 from '../assets/Slide26.jpg';
import Slide20 from '../assets/Slide27.jpg';

const Gallery = () => {
  const slides = [
    {
      id: 1,
      url: Slide1,
    },
    {
      id: 2,
      url: Slide2,
    },
    {
      id: 3,
      url: Slide3,
    },
    {
      id: 4,
      url: Slide4,
    },
    {
      id: 5,
      url: Slide5,
    },
    {
      id: 6,
      url: Slide6,
    },
    {
      id: 7,
      url: Slide7,
    },
    {
      id: 8,
      url: Slide8,
    },
    {
      id: 9,
      url: Slide9,
    },
    {
      id: 10,
      url: Slide10,
    },
    {
      id: 11,
      url: Slide11,
    },
    {
      id: 12,
      url: Slide12,
    },
    {
      id: 13,
      url: Slide13,
    },
    {
      id: 14,
      url: Slide14,
    },
    {
      id: 15,
      url: Slide15,
    },
    {
      id: 16,
      url: Slide16,
    },
    {
      id: 17,
      url: Slide17,
    },
    {
      id: 18,
      url: Slide18,
    },
    {
      id: 19,
      url: Slide19,
    },
    {
      id: 20,
      url: Slide20,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="max-w-[1400px] h-[840px] w-full m-auto py-28 px-4 relative group">
        <div
          style={{ backgroundImage: `url(${slides[currentIndex].url})`,backgroundRepeat: 'no-repeat', }}
          className="w-full h-[60vh] md:h-[80vh] rounded-2xl bg-center bg-cover"
        ></div>
        {/* Left Arrow */}
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactLeft onClick={prevSlide} size={30} />
        </div>
        {/* Right Arrow */}
        <div className="hidden group-hover:block absolute top-[50%] -translate-x-0  right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
          <BsChevronCompactRight onClick={nextSlide} size={30} />
        </div>
        <div className="flex top-4 justify-center py-2">
          {slides.map((slide, slideIndex) => (
            <div
              key={slide.id}
              onClick={() => goToSlide(slideIndex)}
              className={`text-2xl cursor-pointer ${
                slideIndex === currentIndex ? 'text-blue-500' : ''
              }`}
            >
              <RiRadioButtonLine />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Gallery;
