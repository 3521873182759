import React, { useState, useEffect } from 'react';

function Toast({ message, duration = 3000, onClose }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
    const timer = setTimeout(() => {
      setVisible(false);
      onClose();
    }, duration);
    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return (
    <div className="fixed bottom-20 left-1/2 transform -translate-x-1/2 bg-yellow-400 text-gray-800 py-3 px-4 rounded-md shadow-md">
      
      <p className="text-lg font-semibold">{message}</p>
    </div>
  );
}

export default Toast;
