import React from 'react';
import otiumLogo from '../assets/otiumLogo.png';

const Footer = () => {
  return (
  <footer className="bg-slate-900 py-2 flex items-center">
  <div className="container mx-auto text-white flex justify-center items-center gap-2">
    <a href="/">
      <img className="w-[90px]"
        src={otiumLogo}
        alt=''
      />
    </a>
    <span className='justify-center items-center'>Copyright &copy; 2023. All rights reserved.</span>
  </div>
  </footer>
  );
};

export default Footer;
