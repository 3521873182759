import logo from './logo.svg';
import './App.css';
import UtilitiesDesign from './components/UtilitiesDesign';
import Gallery from './components/gallery';
import Header from './components/Header';
import Home from './components/Home';
import Book from './components/book';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';


function App() {
  return (
    <BrowserRouter>
     <Header/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/book" element={<Book/>}/>
      <Route path="/gallery" element={<Gallery/>}/>
      
    </Routes>
    <Footer/>
    </BrowserRouter>
        
  );
}
export default App;
