import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { EffectFade, Autoplay } from 'swiper';
import Slide7 from '../assets/Slide7.jpg'
import Slide3 from '../assets/Slide3.jpg'
import Slide5 from '../assets/Slide5.jpg'
import Slide6 from '../assets/Slide6.jpg'

const slides =[
{
  title: 'Enjoy the Nature and relax at this Luxury Villa',
  bg: Slide7,
  btnText: 'Rooms and Amenities',
},
{
  title: 'Enjoy the Nature and relax at this Luxury Villa',
  bg: Slide3,
  btnText: 'Rooms and Amenities',
},
{
  title: 'Enjoy the Nature and relax at this Luxury Villa',
  bg: Slide5,
  btnText: 'Rooms and Amenities',
},
{
  title: 'Enjoy the Nature and relax at this Luxury Villa',
  bg: Slide6,
  btnText: 'Rooms and Amenities',
},
];

const HeroSlider = () => {
  return( 
  <Swiper 
    modules={[EffectFade, Autoplay]}
    effect={'fade'}
    Loop={true}
    autoplay={{
      delay:3000,
      disableOnInteraction:false,
    }}
    className="heroSlider h-[600px] lg:h-[660px]">
    {
      slides.map((slide, index) =>{
        const {title, bg, btnText} = slide;
        return (
          <SwiperSlide className="h-full bg-blue-400 relative flex justify-center items-center" key={index}>
            <div>
              Just Enjoy! 
            </div>
            <div className="absolute top-0 w-full h-full">
              <img
                className="object-cover h-full w-full"
                src={slide.bg}
                alt=""
              />
            </div>
            <div className="absolute top-0 w-full h-full bg-black/50"></div>
          </SwiperSlide>
        );
      })
    }
  </Swiper>
  );
}

export default HeroSlider;
